
.ivu-select-custom-icon {
  color: #808695;
  font-size: 14px;
  line-height: 1;
  position: absolute;
  right: 8px;
  top: calc(50% - 7px);
}

.ivu-select-no-border {
  position: relative;
  height: 40px;
}

.ivu-select-input {
  cursor: initial;
}
