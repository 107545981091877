

.rule-settings {
  min-height: calc(100% - 62px);
}
.sticky-footer {
  background: #fff;
  border-top: 1px solid #e8e8e8;
  bottom: 0;
  left: 0;
  padding: 10px 0;
  position: sticky;
  text-align: right;
  width: 100%;
}

.ivu-modal-body {
  .sticky-footer {
    padding: 10px 0 0;
  }
}
