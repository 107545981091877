
:deep(.ivu-badge-count) {
  box-shadow: none;
  font-family: inherit;
  font-weight: bold;
  font-size: 10px;
  height: 15px;
  line-height: 12px;
  min-width: 15px;
  padding: 0 0px;
  right: 8px;
  top: -4px;
}
