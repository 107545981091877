$primary-color: #1A202C;
$link-color: #6190e8;
$body-background: #f9fbfc;
$layout-body-background: #f9fbfc;
$font-size-base: 14px;
$font-size-small: 12px;
$font-size-large: $font-size-base;
$border-color-base: #dcdee2;

$spin-dot-size-small: 22px;
$spin-dot-size: 30px;
$spin-dot-size-large: 42px;

$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;
