
@import 'application/styles/variables';

.header {
  background-color: $primary-color;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;

  .ivu-btn {
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;

    .ion {
      vertical-align: sub;
      line-height: 1;
    }

    .ion-md-arrow-round-back {
      font-size: 20px;
    }
  }
}

.ivu-tabs-tab {
  padding: 8px 32px;
  margin: 0;

  @media screen and (max-width: $breakpoint-md) {
    padding: 8px 18px;
  }
}

a.ivu-tabs-tab {
  color: inherit;
}

.ivu-tabs-tab-active {
  border-bottom: 3px solid $primary-color;
}

.ivu-tabs-tab {
  user-select: none;
}

.ivu-tabs-scroll {
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
